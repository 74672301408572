export const MoreIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="30"
    height="30"
    viewBox="0 0 24 24"
  >
    <circle cx="12" cy="12" r="1.5" fill="#FFFFFF" />
    <circle cx="19" cy="12" r="1.5" fill="#FFFFFF" />
    <circle cx="5" cy="12" r="1.5" fill="#FFFFFF" />
  </svg>
);
