/* eslint-disable react/jsx-no-target-blank */
import React from "react";

import { Menu, Transition } from "@headlessui/react";
import { Fragment } from "react";

import BridgeImage from "../assets/mobile-bar/42.png";
import StakeImage from "../assets/mobile-bar/43.png";
import TradeImage from "../assets/mobile-bar/44.png";
import LaunchpadImage from "../assets/mobile-bar/45.png";
import { MoreIcon } from "../assets/Icons";
// import img from "../"
const MobileBar = () => {
  return (
    <div className="md:hidden z-[99] flex fixed bottom-0 w-full header-bg justify-around px-3 h-[70px]">
      <a
        href="https://bridge.dexo.exchange/bridge2"
        target="_blank"
        className="flex flex-col justify-center items-center"
      >
        <img
          alt="bridge"
          className="w-[30px] h-[30px] object-contain"
          src={BridgeImage}
        />
        <span className="text-white text-[13px]">Bridge</span>
      </a>

      <a
        href="https://staking.dexo.exchange/"
        target="_blank"
        className="flex flex-col justify-center items-center"
      >
        <img
          alt="stake"
          className="w-[30px] h-[30px] object-contain"
          src={StakeImage}
        />
        <span className="text-white text-[13px]">Staking</span>
      </a>

      <a
        href="https://app.dexo.exchange/"
        target="_blank"
        className="flex flex-col justify-center items-center"
      >
        <img
          alt="stake"
          className="w-[30px] h-[30px] object-contain"
          src={TradeImage}
        />
        <span className="text-white text-[13px]">Trade</span>
      </a>
      <a
        href="https://staking.dexo.exchange/"
        target="_blank"
        className="flex flex-col justify-center items-center"
      >
        <img
          alt="stake"
          className="w-[30px] h-[30px] object-contain"
          src={LaunchpadImage}
        />
        <span className="text-white text-[13px]">Launchpad</span>
      </a>
      <div className="flex justify-center items-center">
        {/* <MenuDropdown>
          <div className="flex flex-col justify-center items-center">
            <MoreIcon />
            <span className="text-white text-[13px]">More</span>
          </div>
        </MenuDropdown> */}

        <div className="relative">
          <Menu as="div" className="relative">
            <Menu.Button className="1">
              <div className="flex flex-col justify-center items-center">
                <MoreIcon />
                <span className="text-white text-[13px]">More</span>
              </div>
            </Menu.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="absolute mt-4 border-[2px] border-white right-0 w-56 bottom-full divide-y divide-gray-100 rounded-md box-gradient shadow-lg ring-1 ring-black/5 focus:outline-none">
                <div className="px-1 py-1 ">
                  <>
                    <a
                      className="flex py-1 px-3 items-center space-x-3"
                      href="https://bridge.dexo.exchange/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <span className="text-base text-white">
                        Anonymous Transfer{" "}
                      </span>
                    </a>
                    <a
                      className="flex py-1 px-3 items-center space-x-3"
                      href="https://bridge.dexo.exchange/crypto-prepaid-visa"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <span className="text-base text-white">
                        Crypto Visa Card
                      </span>
                    </a>

                    <a
                      className="flex py-1 px-3 items-center space-x-3"
                      href="https://nft.dexo.exchange/collections"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <span className="text-base text-white">
                        NFT Marketplace
                      </span>
                    </a>
                  </>
                </div>
              </Menu.Items>
            </Transition>
          </Menu>
        </div>
      </div>
    </div>
  );
};

export default MobileBar;
